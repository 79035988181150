import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import axios from 'axios';
import styled from 'styled-components';
import classNames from 'classnames';

const ForgotPasswordPage = () => {
  const [form, setForm] = useState({
    email: '',
  });
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState({
    email: {
      error: false,
      message: 'Sorry, this email does not exist.',
    },
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset errors.
    setErrors({
      ...errors,
      email: { ...errors.email, error: false },
    });

    axios
      .post(`${process.env.GATSBY_ADMIN_URL}/auth/forgot-password`, {
        email: form.email,
      })
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        if (
          error?.response?.data?.data?.[0]?.messages?.[0]?.id === 'Auth.form.error.user.not-exist'
        ) {
          setErrors({ email: { ...errors.email, error: true } });
        }
      });
  };

  return (
    <Layout>
      <SEO title="Forgot Password" />
      <Row className="wysiwyg-content">
        <h1>Forgot Password</h1>
        {sent ? (
          <p>A reset link has been sent to your email. Thanks!</p>
        ) : (
          <form method="post" onSubmit={handleSubmit} style={{ maxWidth: '300px' }}>
            <label htmlFor="email" className={classNames({ 'h-error': errors.email.error })}>
              Enter your E-mail
              <input
                type="email"
                name="email"
                id="email"
                value={form.email}
                onChange={handleInputChange}
                required
              />
              {errors.email.error && (
                <StyledMessageSmall error={errors.email.error}>
                  {errors.email.message}
                </StyledMessageSmall>
              )}
            </label>
            <input type="submit" className="button" value="Request Password Reset" />
          </form>
        )}
      </Row>
    </Layout>
  );
};

export default ForgotPasswordPage;

const StyledMessageSmall = styled.small`
  color: ${({ error }) => (error ? 'var(--c-error)' : 'var(--c-gray)')};

  strong {
    color: var(--c-black);
    font-size: 16px;
  }
`;
